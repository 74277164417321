import './App.css';

//ICONS
import { MdOutlineMail } from 'react-icons/md';
import { FaFacebookSquare } from 'react-icons/fa';
import { BsInstagram } from 'react-icons/bs';

function App() {
  return (
    <div className='container'>
      <div className="container-general">
        <div className="logo-container">
          <img className="logo-brip" width="500" height="400" src="https://res.cloudinary.com/dgq7jpui9/image/upload/v1662070812/LOGO_FEST_957_BORDE_BLANCO_PNG_pltneu.png" atl="La Fest 95.7" />
        </div>
        <div className='tittle-container-brip'>
          <h2> ¡Nos estamos renovando!</h2>
          <h5> ¡Visita nuestras redes o líneas de contacto para más novedades!  </h5>
        </div>
        <div className='container-redes'>
          <div className='redes-child'>
            <BsInstagram className='icon-style' />
            <a target="_blank" className='nombre-redes' href='https://www.instagram.com/radiofestlp/'>
              Instagram radiofestlp
            </a>
          </div>

          <div className='redes-child'>
            <img className='imagen-pais' src="https://res.cloudinary.com/briptravel/image/upload/v1657564394/BripTravel/icon-argentina_trnlez.svg" alt="Brip Travel Argentina" />
            <a target="_blank" href='https://api.whatsapp.com/send/?phone=5492213503699&text&type=phone_number&app_absent=0'>
              +54 2213503699
            </a>
          </div>

          <div className='redes-child'>
            <FaFacebookSquare className='icon-style' />
            <a target="_blank" className='nombre-redes' href='https://www.facebook.com/radiofestlp'>
              Facebook radiofestlp
            </a>
          </div>

        </div>

        <div className='container-redes redes-down'>
          <div className='container-reproductor'>
            <iframe className='repro-cont' border="0" frameborder="NO" width="350px" height="495px" scrolling="NO" 
            marginheight="0px" marginwidth="0px" allowtransparency="yes" 
            src="https://www.solumedia.com.ar/radios/6520/index.html">

            </iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
